.mapContainer {
    position: relative;
    flex-grow: 1;
    min-width: 320px;
    min-height: 360px;
}

.map {
  width: 100%;
  height: 100%;
}

.wrapper{
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  border-radius: 16px 0 0 16px;
  overflow: hidden;
  box-shadow: -4px 0px 24px 0px #677A8E3D;

  .toolsWrapper{
    position: absolute;
    bottom: 138px;
    width: fit-content;
    height: fit-content;
    left: 24px;
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.progressScreen{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  flex-direction: column;
}