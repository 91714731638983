.wrapper{
  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 16px;
  border-radius: 8px;
  gap: 12px;
  width: 100%;
  height: fit-content;

  .header{
    display: flex;
    justify-content: space-between;
    align-items: center;

    .headerTitles{
      display: flex;
      gap: 8px;
      align-items: center;

      .title{
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
      }

      .pinWrapper{
        font-size: 14px;
        color: var(--palette-grey-700);
        background-color: var(--palette-grey-100);
        border-radius: 8px;
        padding: 4px 10px;
        margin-right: 10px;

        .pin{
          vertical-align: middle;
          padding-bottom: 2px;
          width: 16px;
          margin-right: 5px;
        }
      }
    }

    .menuButton{
      width: 40px;
      height: 40px;
    }
  }

  .body{
    width: 100%;
    height: 100%;
    overflow: auto;
  }
}

.boldFont{
  font-weight: 500;
  color: var(--palette-grey-900);
}