.MuiPickersDay-root {
    width: 32px;
    height: 32px;
    margin: 0 2px;
    border: 1px transparent solid;
    border-radius: 8px;

    &.isBetweenFirstAndLast {
        background: transparent;
        color: var(--palette-primary-dark);

        &:hover, &:focus {
            color: white;
            background: var(--palette-primary-dark);
        }
    }

    &.isFirst, &.isLast {
        color: white;
        background: var(--palette-primary-dark);

        &:hover, &:focus {
            color: white;
            background: var(--palette-primary-dark);
        }
    }

    &.isToday {
        color: var(--palette-primary-dark);
        border-color: var(--palette-primary-dark);

        &.isFirst, &.isLast, &.isBetweenFirstAndLast {
            color: white;
        }
    }

    &.isOutsideCurrentMonth {
        color: var(--palette-action-disabled);
    }

    &:hover, &:focus {
        color: var(--palette-primary-dark);
        background: var(--palette-grey-background);
    }

    &.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus {
        color: white;
        background: var(--palette-primary-dark);
    }
}

.dateTimeRangePicker {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: max-content;
    background: white;
    border-radius: 16px;
    padding: 16px;
    box-shadow: var(--palette-shadow-middle);

    &-toolbar {
        display: flex;
        justify-content: flex-end;
        gap: 8px;
    }
}

.RangePickersDay {
    width: max-content;

    .MuiPickersDay-root.Mui-selected {
        color: var(--palette-grey-700);
        background: transparent;
        font-weight: 400;

        &:hover {
            color: var(--palette-primary-dark);
            background: var(--palette-grey-background);
        }
    }

    &.isFirst, &.isLast, &.isBetweenFirstAndLast {
        background: var(--palette-grey-100);
    }

    &.isBetweenFirstAndLast {
        border-radius: 0;

        & .MuiPickersDay-root {
            color: var(--palette-primary-dark);
            background: var(--palette-grey-100);
            font-weight: 400;

            &:hover {
                color: white;
                background: var(--palette-primary-dark);
            }
        }

        & .Mui-selected {
            color: var(--palette-primary-dark);
            background: var(--palette-grey-100);
        }
    }

    &.isFirst {
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        margin-left: 2px;

        & .MuiPickersDay-root {
            margin-left: 0;
        }

        & .Mui-selected {
            color: white;
            background: var(--palette-primary-dark);
        }
    }

    &.isLast {
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        margin-right: 2px;

        & .MuiPickersDay-root {
            margin-right: 0;
        }

        & .Mui-selected {
            color: white;
            background: var(--palette-primary-dark);
        }
    }

    &.isFirst.isLast.isBetweenFirstAndLast {
        border-radius: 8px;
    }
}

.MuiPickersMonth-monthButton {
    width: 72px;
    height: 32px;
    margin: 0;
    font-size: 14px;
    border-radius: 8px;
    border: 1px transparent solid;
    background: var(--palette-grey-background);
    color: var(--palette-grey-700);

    &.Mui-selected {
        color: white;
        background: var(--palette-primary-dark);

        &:hover {
            color: var(--palette-primary-dark);
            background: white;
            border-color: var(--palette-primary-dark);
        }
    }
}

.MuiPickersCalendarHeader-root {
    display: none;
}

.MuiDayCalendar {
    &-root {
        width: 248px;
    }

    &-header {
        display: flex;
        gap: 8px;
        margin-top: 16px;
        margin-bottom: 16px;
    }

    &-weekDayLabel {
        color: var(--palette-grey-800);
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        margin: 0;
        padding: 0;
        width: 32px;
        height: 16px;
    }

    &-monthContainer {
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    &-weekContainer {
        margin: 0;
    }
}

.MuiMonthCalendar {
    &-root {
        margin: 16px 0;
        padding: 0;
        width: 100%;
        display: grid;
        justify-content: center;
        gap: 16px;
        grid-template-columns: repeat(3, 72px);
    }
}

.MuiYearCalendar {
    &-root {
        margin: 16px 0;
        width: 248px;
        gap: 8px;
        height: 100%;
    }
}

.MuiDateCalendar {
    &-root {
        height: max-content;
        width: 100%;
    }
}

.MuiDivider {
    &-root {
        border-color: var(--palette-grey-100);
        width: 100%;
    }

    &-vertical {
        width: 1px;
    }
}