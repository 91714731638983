.wrapper{
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;

  .button{
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 24px;
    border: 1px solid var(--palette-grey-100);
    border-radius: 12px;

    .buttonText{
      font-size: 16px;
      font-weight: 500;
      color: var(--palette-grey-900);
    }
  }
}