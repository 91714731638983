.tableWrapper{
  height: 100%;
  padding: 0 !important;

  .progressScreen{
    width: 100%;
    height: 100%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    flex-direction: column;
  }

  .tableContentWrapper{
    min-width: 650px;

    .headerBold{
      border-right: 1px solid var(--palette-grey-100);
      font-weight:500;
      font-size:14px;
      color:var(--palette-grey-600);
    }

    .headerSecond{
      top: 57px;
    }

    .headerThin{
      top: 57px;
      font-weight:400;
      font-size:14px;
      color:var(--palette-grey-500);
    }

    .tableBodyTitle{
      font-weight:500;
      font-size:14px;
      color:var(--palette-grey-900);
      min-width: 230px;
    }

    .tableBodyContentCell{
      min-width: 85px;
      font-weight:400;
      font-size:14px;
      color:var(--palette-grey-900);
    }

    .tableCell{
      border-bottom: 1px solid var(--palette-grey-100);
    }

    .tableHeaderCell{
      border: none;
    }

    .temperatureVeryCold{
      background-color: var(--palette-very-cold);
    }

    .temperatureCold{
      background-color: var(--palette-cold);
    }

    .temperatureChilly{
      background-color: var(--palette-chilly);
    }

    .temperatureAroundZero{
      background-color: var(--palette-around-zero);
    }

    .temperatureCool{
      background-color: var(--palette-cool);
    }

    .temperatureWarm{
      background-color: var(--palette-warm);
    }

    .temperatureHeat{
      background-color: var(--palette-heat);
    }

    .averageWindSpeedCell{
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 4px;
    }

    .weatherIconWrapper{
      width: 100%;
      display:flex;
      justify-content:center;

      .weatherIcon{
        scale: 1.75;
        width: 24px;
        height: 24px;
      }
    }
  }

  .borderRightInclude{
    border-right: 1px solid var(--palette-grey-100)
  }

  .borderRightNone{
    border-right: none
  }
}

