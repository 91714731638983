.pin-point-tool {
    margin: 10px;
    display: block;
    width: 28px;
    height: 28px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    background: url('../../../../shared/assets/svg/pin_point.svg') center center no-repeat #fff;
    background-size: 25px 25px;
    box-shadow: 0 0 0 2px rgba(0, 0, 0, .1);
}

.pin-point-tool.active {
    background-color: #b4deff;
}

.forecastButton{
    background: var(--palette-primary-dark);
    color: white;
    border: 1px solid white;
    border-radius: 8px;
    width: 120px;
    height: 30px;
    cursor: pointer;
    transition: .25s;
}

.forecastButton:hover{
    background: white;
    color: var(--palette-primary-dark);
    border: 1px solid var(--palette-primary-dark);
    transition: .25s;
}

.modal_wrapper{
    width: 100%;
    height: 100%;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    position: absolute;
    background: rgba(0,0,0,0.5);
    z-index: 3;
}

.modal{
    position: absolute;
    left: 50%;
    top: 50%;
    transform:translate(-50%, -50%);
    background-color: white;
    width: fit-content;
    min-height: 200px;
    min-width: 700px;
    height: fit-content;
    border: 1px solid var(--palette-grey-600);
    border-radius: 12px;
    z-index: 4;
}

.modal_inner{
    display: flex;
    flex-direction: column;
    padding: 24px;
    gap: 12px;
    height: 100%;
}

.modal_header{
    font-size: 24px;
    font-weight: 600;
    color: var(--palette-grey-600);
    padding-bottom: 8px;
}

.modal_text{
    font-size: 14px;
    font-weight: 400;
    color: var(--palette-grey-600);
    max-height: 650px;
    overflow-x: auto;
}

.modal_content{
    display: flex;
    height: 100%;
}

.block_wrapper{
    display: flex;
    flex-direction: column;
    gap: 40px;
    align-items: center;
    padding: 0 5px;
    border-right: 1px solid var(--palette-grey-400);
    width: 100px;
}

.block_wrapper:last-child{
    border-right: none;
}

.block_header{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.block_line{
    display: flex;
    align-items: end;
    justify-content: center;
    text-align: center;
}

.block_temps{
    display: flex;
    gap: 5px;
    align-items: end;
}

.day_temp{
    font-size: 30px;
    color: #E86F00;
}

.night_temp{
    font-size: 20px;
    color: #1B51B3;
}

.block_precipitation{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.precipitation_percent{
    font-size: 18px;
    font-weight: 500;
    color: var(--palette-grey-500);
}

.weatherCode{
    font-size: 12px;
    font-weight: 400;
    color: var(--palette-grey-400);
}

.date{
    font-size: 16px;
    font-weight: 500;
    color: var(--palette-grey-500);
}

.forecast_image{
    transform: scale(1.75);
}