.wrapper{
  width: 100%;
  height: 100%;
  padding: 16px 24px;
  overflow-y: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  box-shadow: 0 4px 24px 0 #677A8E29 inset;
  z-index: 5;


  .emptyStatesWrapper{
    width:100%;
    height:100%;
  }
}