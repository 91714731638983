.wrapper{
  position: relative;

  .viewsWrapper{
    position: absolute;
    left: -150px;
    top: 50px;
    z-index: 1;
    width: 200px;
    background-color: #ffffff;
    padding: 10px 15px;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 4px;
    box-shadow: 0 1px 3px rgba(103, 122, 142, 0.24);
    border-radius: 8px;
    border: 0;

    .viewsMenusWrapper{
      position: absolute;
      left: 205px;
      top: 39px;
      min-width: 220px;
      margin: 0;
      background-color: #ffffff;
      cursor: default;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      gap: 4px;
      font-size: 14px;

      .menuItem{
        display: flex;
        justify-content: start;
        flex-direction: row;
        align-items: center;

        .trashIcon{
          color: var(--palette-error-main);
        }
      }
    }
  }
}