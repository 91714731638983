.bottomWrapper{
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;

  .timeWrapper{
    display: flex;
    flex-direction: column;
    gap: 4px;

    .timeContent{
      display: flex;
      gap: 4px;
    }
  }
}