.wrapper{
  display: flex;
  gap: 16px;
  flex-direction: column;

  .content{
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 24px;
    border: 1px solid var(--palette-grey-100);
    border-radius: 12px;

    .menuWrapper{
      display: flex;
      align-items: center;
      gap: 16px;
      background-color: #fff;

      .menuItem{
        padding: 4px 12px;
        width: fit-content;
        height: 36px;
        color: var(--palette-grey-800);
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        border: none;
        }
    }

    .checkBoxMenu{
      padding: 16px;
      display: flex;
      gap: 24px;
      border-radius: 8px;
      width: 100%;
      background-color: var(--palette-info-light);

      .checkBoxLabel{
        font-weight: 500;
        font-size:16px;
      }
    }
  }
}