.wrapper{
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
  background-color: #fff;
  padding: 16px 24px 0 24px;

  .upperString{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .title{
      color: var(--palette-grey-600);
    }
  }

  .lowerString{
    display: flex;
    gap: 16px;
    align-items: center;
    justify-content: start;

    .addViewButton{
      width: fit-content;
      font-size: 14px;
      border: none;
    }

    .scrollContainer{
      overflow-x: auto;
      white-space: nowrap;
      max-width: 100%;

      .menuWrapper{
        display: flex;
        align-items: center;
        gap: 16px;
        background-color: #fff;
        height: 100%;
        padding: 0 4px;

        .menuItem{
          height: 100%;
          width: fit-content;
          font-size: 14px;
          font-weight: 500;
          line-height: 20px;
          color: var(--palette-grey-800);
          border-bottom-left-radius: 0 !important;
          border-bottom-right-radius: 0 !important;
          display: flex;
          gap: 10px;
        }

        .menuItemSelected {
          background-color: var(--palette-grey-100);
        }
      }
    }
  }
}

.deleteTitle{
  font-weight: 500;
  color: var(--palette-grey-900);
}