.wrapper{
  min-width: 288px;

  .content{
    gap: 10px;
    padding: 10px 16px;
    width: 400px;

    .filterWrapper{
      min-height: 52px;
      color: var(--palette-grey-500);
      padding: 10px 16px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .filterTitle{
        font-size: 14px;
        font-weight: 400;
      }

      .filter{
        font-size: 12px;
        font-weight: 500;
        background-color: white;
        height: 32px;
        width: 32px;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}