.containerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 500px;
  gap: 16px;

  .headerWrapper {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-content: center;

    .headerTitle {
      display: flex;
      font-weight: 500;
      font-size: 24px;
      color: var(--palette-grey-900);
    }
  }

  .contentWrapper {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }

  .button {
    font-weight: 400;
    font-size: 16px;
    width: 50%;
  }
}