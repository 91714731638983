.wrapper{
  box-sizing: border-box;
  border-radius: 16xp;
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  padding-top: 2px;
  gap: 24px;
  height: 100%;
  overflow: hidden;

  .upperBlockWrapper{
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 16px;
    gap: 12px;
    border: 1px solid #f0f2f5;
    border-radius: 12px;

    .reportIncludeBlock{
      width: 100%;
      height: 100%;
      padding: 5px 0;
      border: 1px solid var(--palette-grey-100);
      border-radius: 12px;

      .reportIncludeBlockLabel{
        font-weight:600;
      }
    }
  }

  .reportSettingsWrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;
    height: 100%;
    overflow: hidden;

    .reportSettings{
      height: fit-content;

      .reportSettingsValue{
        padding: 4px 12px;
        height: 32px;
        width: 192px;
      }
    }
  }
}