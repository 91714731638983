.wrapper{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .header{
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    background-color: white;
    padding: 12px 24px;
  }

  .content{
    display: flex;
    gap: 16px;
    width: 100%;
    align-items: center;
    padding: 16px 24px;

    .contentItem{
      display: flex;
      flex-direction: column;
      gap: 16px;
      padding: 16px;
      border-radius: 8px;
      min-height: 472px;
      background-color: white;
      width: 50%;

      .title{
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;

        .radioTitle{
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
        }
      }

      .selector{
        display: flex;
        flex-direction: column;
        gap: 4px;
        height: 230px;

        .input{
          width: 400px;
        }
      }

      .unitsWrapper{
        display: flex;
        flex-direction: column;
        gap:4px;
      }

      .subTitle{
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }

      .listWrapper{
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        max-height: 200px;
        height: 0;
        background-color: white;
        border-radius: 12px;
        padding: 0;

        .listTitle{
          font-size: 12px;
          font-weight: 400;
          color: var(--palette-grey-400);
        }

        .locItem{
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          cursor: pointer;
        }
      }

      .isOpenSearching{
        width: 400px;
        height: fit-content;
        padding: 12px;
      }
    }
  }
}