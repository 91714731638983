.timeline {
    position: absolute;
    display: flex;
    gap: 16px;
    align-items: center;
    width: 100%;
    height: 100px;
    bottom: 0;
    background-color: rgba(240, 242, 245, 0.1);
    box-shadow: rgba(0, 0, 0, 0.12) 0px -2px 12px 0px;
    backdrop-filter: blur(2px);
    padding: 24px;
    z-index: 3;
}

.speedButtonsContainer {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.scrubberContainer {
    position: relative;
    background: var(--palette-grey-100);
    width: 100%;
    height: 48px;
    border-radius: 8px;
    border: 2px white solid;
}

.scrubber {
    position: absolute;
    width: 100%;
    z-index: 2;
    opacity: 0.6;
    background: transparent;
    height: 44px;
    border: none;
    padding: 0;
    border-radius: 8px;
    overflow: hidden;
}

.futureOrPast {
    position: absolute;
    border: none;
    height: 44px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    z-index: 5;
    pointer-events: none;
}

.scrubber {
    -webkit-appearance: none;
    width: 100%;

    &:focus::-ms-fill-lower {
        background: rgba(27, 27, 27, 0.28);
    }

    &:focus::-ms-fill-upper {
        background: rgba(152, 152, 152, 0.28);
    }

    &::-webkit-slider-runnable-track {
        width: 100%;
        height: 48px;
        cursor: pointer;
        background: transparent;
    }

    &::-webkit-slider-thumb {
        height: 48px;
        width: 10px;
        background: var(--basecolor);
        cursor: pointer;
        -webkit-appearance: none;
    }

    &::-moz-range-track {
        width: 100%;
        height: 48px;
        cursor: pointer;
        background: rgba(27, 27, 27, 0.28);
    }

    &::-moz-range-thumb {
        height: 48px;
        width: 10px;
        background: var(--basecolor);
        cursor: pointer;
        border-radius: 0;
        border: none;
    }

    &::-ms-track {
        width: 100%;
        height: 48px;
        cursor: pointer;
        background: transparent;
        border-color: transparent;
        color: transparent;
    }

    &::-ms-fill-lower {
        background: rgba(0, 0, 0, 0.28);
    }

    &::-ms-fill-upper {
        background: rgba(27, 27, 27, 0.28);
    }

    &::-ms-thumb {
        width: 10px;
        background: var(--basecolor);
        cursor: pointer;
        height: 48px;
    }
}

.scrubberDetails {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.datetime {
    line-height: 16px;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.mapMode {
    font-style: italic;
    line-height: 16px;
    color: var(--palette-grey-500);
    text-overflow: ellipsis;
    white-space: nowrap;
}
