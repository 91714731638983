* {
}

html {
    min-width: 320px;
    min-height: 360px;
}

.baronmap #map {
    width: 100%;
    height: 100%;
}

.baronmap {
    position: relative;
    flex-grow: 1;
    min-width: 320px;
    min-height: 360px;
}

.baronmap .locationwrapper {
    position: fixed;
    z-index: 10000;
    background: rgba(255, 255, 255, 0.8);
    width: 320px;
    top: 15px;
    right: 15px;
    user-select: none;
    height: 51px;
    line-height: 51px;
    box-sizing: border-box;
    border-radius: 2px;
}

.baronmap .locationwrapper.locationopened {
    background: #fff;
}

.baronmap .locationwrapper .cls-arrow {
    stroke: var(--basecolor);
}

.baronmap .locationwrapper .locationheadertempandcond {
    vertical-align: top;
}

.baronmap .locationwrapper .locationheadertempandcond .condicon {
    width: 32px;
    height: 32px;
    margin-top: 10px;
}

.baronmap .locationwrapper .locationheadername {
    width: 240px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.baronmap .locationwrapper .cls-1 {
    fill: #000000;
}

.baronmap .locationsearchicon {
    margin-top: 4px;
}

.baronmap .logo {
    bottom: 70px;
    right: 15px;
    width: initial;
    height: initial;
    max-width: 30%;
    max-height: 30%;
    position: fixed;
    opacity: 0.7;
    z-index: 99;
    display: block;
}

.baronmap .locationheader {
    height: 51px;
    white-space: nowrap;
}

.mapboxgl-ctrl-bottom-right, .maplibregl-ctrl-bottom-right{
    bottom: 165px;
    z-index: 0;
}

html .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl{
    margin: 0 5px 0 0;
}

@media screen and (max-width: 520px) {
    .baronmap #map {
        top: 35px !important;
    }

    .mapboxgl-map .mapboxgl-ctrl-bottom-right .mapboxgl-ctrl {
        margin-bottom: 40px;
    }


    .baronmap .productswrapper {
        top: 0;
        left: 0;
    }

    .baronmap .productpanellist,
    .baronmap .productpanellegend,
    .baronmap .productpanelopacity,
    .baronmap .productswrapper.opened,
    .baronmap .citieslist,
    .baronmap .recentlist {
        width: 100%;
    }

    .baronmap .productswrapper .cls-layers {
        stroke: var(--basecolor);
        fill: var(--basecolor);
    }

    .baronmap .citieslist .citieslistitem .citieslistlabel {
        width: calc(100% - 80px);
    }

    .baronmap .locationsearchwrapper input {
        width: calc(100% - 60px);
    }

    .baronmap .locationsearchicon {
        margin-top: 6px;
    }

    .baronmap .productswrapper .productsbtn {
        background: transparent;
        stroke: var(--basecolor);
    }

    .baronmap .productswrapper.opened .productsbtn {
        background: transparent;
    }

    .baronmap .locationwrapper {
        top: 0;
        right: 0;
        height: 35px;
        line-height: 35px;
        padding-right: 0;
    }

    .baronmap .locationwrapper .location {
        position: fixed;
        width: 100%;
        left: 0;
    }

    .baronmap .locationheader {
        height: 35px;
    }

    .baronmap .locationwrapper .locationheaderarrow {
        height: 35px;
        float: right;
        margin-right: 10px;
    }

    .baronmap .locationwrapper .locationheadertempandcond .condicon {
        margin-top: 1px;
    }

    .baronmap .timeline {
        left: 0;
        bottom: 43px;
        max-width: 100%;
        white-space: nowrap;
    }

    .baronmap .legendswrapper {
        height: 28px;
        left: 0;
        max-width: 100%;
        box-sizing: border-box;
        padding: 0;
    }

    .baronmap .locationwrapper .locationheadername {
        float: right;
        text-indent: 10px;
        width: 220px;
    }

    .baronmap .legendswrapper {
        bottom: 0;
    }

    .baronmap .timeline {
        bottom: 28px;
    }

    }

    .baronmap .timeline {
        width: 100%;
        max-width: 475px;
        padding: 0;
        box-sizing: border-box;
    }


@media screen and (max-width: 450px) {
    .baronmap .logo {
        bottom: 30px;
        right: 2px;
    }
}

/******************OVERRIDE CSS FOR POPUP*******************************/
body .impmarker {
    background: transparent;
}
body .impmarker .impmarker-header {
    background: #fff;
    margin-right: 0;
    width: 240px;
    height: 36px;
    line-height: 36px;
    color: #666666;;
    padding-left: 10px;
    border-radius: 8px;
    border: 1px solid var(--palette-grey-100);
}

body .impmarker.impmarker-noclose .impmarker-header {
    margin-right: 0;
    width: 250px;
}

body .impmarker .impmarker-closebtn {
    height: 35px;
    line-height: 35px;
    width: 35px;
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
    text-align: center;
    font-size: 20px;
    background-color: #333333;
    color: #FFF;
    border-radius: 50px;
    top: -10px;
    right: -13px;
    position: absolute;
}

body .impmarker .impmarker-body * {
    color: #666666;
}

body .impmarker .impmarker-body {
    background: #fff;
    border-radius: 8px;
    border: 1px solid var(--palette-grey-100);
    padding: 6px 10px;
    max-width: 300px;
    min-width: 240px;
    overflow: auto;
}

.impmarker-simplebody-line-value {
    font-weight: bold;
    color: #000000 !important;
}

body .mapboxgl-popup-content, .maplibregl-popup-content {
    display: flex;
    align-items: center;
    background: var(--palette-grey-background);
    border-radius: 8px;
    box-shadow: 0 4px 12px 0 #677A8E3D;
    border: 1px solid var(--palette-grey-100);
    padding: 4px 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: fit-content;
    min-width: 236px;
    pointer-events: auto;
    position: relative;
    min-height: 36px;
}

.custom-popup {
    background-color: #fff;
    color: #666666;
    font-size: 12px;
}

.custom-popup-text-wrapper{
    border-bottom: 1px solid var(--palette-grey-100);
    width: 100%;
    padding: 5px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
}

.custom-popup-text-wrapper:last-child{
    border-bottom: none;
}

.custom-popup-text {
    color: var(--palette-grey-900);
    font-weight: 600;
    font-size: 12px;
}

.custom-popup-title {
    color: var(--palette-grey-700);
    font-weight: 400;
    font-size: 12px;
}

.custom-popup-button{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    background-color: white;
    border: 1px solid var(--palette-grey-100);
    border-radius: 8px;
    font-size: 16px;
    color: var(--palette-grey-500);
    position: absolute;
    top: 0;
    right: -28px;
    z-index: 1;
    cursor: pointer;
    transition: .25s;
}

.custom-popup-button:hover{
    background-color: var(--palette-grey-100);
    transition: .25s;
}

.impmarker-simplebody-line span {
    text-transform: capitalize;
}

.impmarker-header-label {
    width: 184px;
    display: inline-block;
    padding: 0 5px;
    vertical-align: top;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: bolder;
    color: #000000;
}


.impmarker-body::-webkit-scrollbar {
    width: 12px
}

.impmarker-body::-webkit-scrollbar-track {
    background: hsla(0, 0%, 58.8%, .25);
    border-radius: 6px
}

.impmarker-body::-webkit-scrollbar-thumb {
    background: hsla(0, 0%, 78.4%, .5);
    border-radius: 6px
}

.mapboxgl-popup, .maplibregl-popup{
    max-width: fit-content !important;
}
