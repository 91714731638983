.wrapper{
  max-width: 288px;
  padding: 24px 0 56px 0;

  .header{
    padding: 0 16px;
    margin-bottom: 24px;

    .searchInput{
      margin-bottom: 10px;
      width:100%;
    }
  }

  .contentWrapper{
    overflow: auto;
    display: flex;
    align-items: center;

    .emptyScreen{
      width:100%;
      height:100%;
    }
  }
}

.locationBoxWrapper{
  overflow-wrap: break-word;
  padding: 8px 8px 8px 12px;
  width: 246px;

  .labelWrapper{
    display: flex;
    width: 100%;
    align-items: center;
    gap: 14px;
    align-self: stretch;
    justify-content: space-between;

    .square{
      width: 12px;
      height: 12px;
      border-radius: 2px;
      background: var(--palette-common-vividRed);
    }

    .label{
      font-size: 14px;
      font-style: normal;
    }

    .selectedLabel{
      font-weight: 500;
      line-height: 24px;
      letter-spacing: 0.035px;
      color: var(--palette-primary-dark);
    }

    .unselectedLabel{
      color: var(--palette-grey-900);
      font-weight: 400;
      line-height: normal;
      letter-spacing: 0.21px;
    }

    .selectedIcon{
      color: var(--palette-primary-dark)
    }

    .unselectedIcon{
      color: var(--palette-grey-500)
    }
  }

  .selectedLocationContentWrapper{
    flex-direction: row;
    border-radius: 8px;
    background: #FFF;

    .subTitle{
      color: var(--palette-grey-800);
      font-size: 12px;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0.12px;
      line-height: 16px;
    }

    .selectedLocationContent{
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      align-self: stretch;

      .severityWrapper{
        width: 100%;
        align-items: center;
        align-self: stretch;
        display: flex;
        flex-grow: 3;
        flex-direction: row;

        .severityItem{
          font-size: 10px;
          height: 10px;
        }
      }

      .severityCountWrapper{
        display: flex;
        align-items: center;
        gap: 4px;
        align-self: stretch;

        .severityCount{
          width: 8px;
          height: 8px;
          border-radius: 2px;
        }
      }

      .severityCountTitle{
        color: var(--palette-grey-600);
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.06px;
      }

      .criticalColor{
        background-color: var(--palette-common-vividRed);
      }
      .severeColor{
        background-color: var(--palette-warning-main);
      }
      .moderateColor{
        background-color: var(--palette-primary-middle);
      }
    }
  }
}

.selectedLocationBoxWrapper{
  background: white;
  border: 1px solid;
  border-color: var(--palette-grey-100);
  border-radius: 8px;
}

