.wrapper{
  position: relative;
  font-size: 14px;
  line-height: 32px;
  color: var(--palette-grey-900);

  .menuWrapper{
    position: absolute;
    top: 45px;
    left: 0;
    width: 200px;
    z-index: 1;
    margin: 0;
    background-color: #ffffff;
    padding: 10px 15px;
    cursor: default;
    display: flex;
    flex-direction: column;
    gap: 4px;

    .loadMenuWrapper{
      position: absolute;
      left: 205px;
      top: 32px;
      min-width: 220px;
      margin: 0;
      background-color: #ffffff;
      cursor: default;
      padding: 10px 15px;
      display: flex;
      flex-direction: column;
      gap: 4px;

      .loadMenuItem{
        display: flex;
        justify-content: start;
        flex-direction: row;
        align-items: center;

        .trashIconColor{
          color: var(--palette-error-main);
          transition: 0.2s;
        }
      }
    }
  }
}