.legendswrapper{
    position: fixed;
    width: 475px;
    height: 40px;
    bottom: 125px;
    right: 5px;
    padding: 0 25px;
    background-color: #000;
}
.legendswrapper *{
    display: inline-block;
}
.legendswrapper .legendsprevbtn,
.legendswrapper .legendsnextbtn {
    position: absolute;
    cursor: pointer;
    background: url('arr.svg') no-repeat center center;
    width: 15px;
    height: 40px;
    margin: 0 5px;
    opacity: 0.6;
    transition: 0.2s;
}
.legendswrapper .legendsprevbtn:hover,
.legendswrapper .legendsnextbtn:hover {
    opacity: 1;
}
.legendswrapper .legendsnextbtn{
    float: right;
}
.legendswrapper .legendsprevbtn{
    transform: rotate(180deg);
    margin-left: -20px;
}
.legendimage{
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-color: #000;
}